<template>
	<article>
		<div class="container w-full relative">
			<img
				class="max-h-40 md:max-h-96 w-full object-cover mt-3"
				:src="articleImage"
				:alt="articleTitle"
			/>
			<div
				class="bottom-right absolute bottom-2 right-3 p-2 rounded font-semibold"
			>
				Image Credits: {{ articleImageCredits }}
			</div>
		</div>

		<div class="flex mt-2 md-2">
			<div class="text-sm flex-none">
				Published on:
				<a class="date thick-underline mb-4" href="#date">{{ publishDate }}</a>
			</div>
			<span class="flex-grow"></span>
			<span class="view-count flex-none text-sm font-normal">
				{{ viewCount }}
				<img
					src="./../assets/icons/eye.svg"
					alt="Eye"
					class="h-4 mb-1 inline dark:hidden"
				/>
				<img
					src="./../assets/icons/eye-white.svg"
					alt="Eye"
					class="dark:inline h-4 mb-1 hidden"
				/>
			</span>
		</div>
		<ul class="hashtags">
			<li class="hashtag" v-for="tag in tags" :key="tag">
				<router-link
					:to="{ name: 'tag', params: { tag: tag } }"
					class="thick-underline"
				>
					{{ tag }}
				</router-link>
			</li>
		</ul>
		<div class="md:col-span-4 mb-8 mt-12">
			<p
				class="article-body-para"
				v-for="paragraph in articleContent"
				:key="paragraph"
			>
				{{ paragraph }}
			</p>
		</div>
	</article>
</template>
<script>
export default {
	name: "CompleteArticleLoaded",
	props: {
		articleImage: String,
		articleTitle: String,
		articleImageCredits: String,
		publishDate: String,
		viewCount: Number,
		tags: Array,
		articleContent: Array,
	},
};
</script>

<style scoped></style>
