<template>
	<div class="grid grid-col-1">
		<div class="w-auto h-auto mx-8">
			<div class="my-4 md:grid md:grid-cols-8 md:gap-8">
				<div class="md:col-span-1"></div>
				<div class="md:col-span-4">
					<TheArticleTitle
						:articleTitle="articleTitle"
						:authorName="authorName"
						:authorUsername="authorUsername"
					/>
					<CompleteArticleLoading v-show="!loaded" />
					<CompleteArticleLoaded
						v-show="loaded"
						v-cloak
						:articleImage="articleImage"
						:articleTitle="articleTitle"
						:articleImageCredits="articleImageCredits"
						:publishDate="publishDate"
						:viewCount="viewCount"
						:tags="tags"
						:articleContent="articleContent"
					/>
					<ShareThisArticle :slug="slug" />
				</div>
				<div class="mt-8 md:col-span-2">
					<h4 class="text-lg uppercase font-bold">Top Tags</h4>
					<TenTags />
					<div class="block no-phone mt-4">
						<h4 class="block text-lg uppercase font-bold mt-12">
							Categories
						</h4>
						<!-- TODO: add categories component </ul> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//packages import
import axios from "axios";

//components import
import TheArticleTitle from "@/components/TheArticleTitle";
import CompleteArticleLoading from "@/components/CompleteArticleLoading";
import CompleteArticleLoaded from "@/components/CompleteArticleLoaded";
import TenTags from "@/components/TenTags";
import ShareThisArticle from "@/components/ShareThisArticle";

export default {
	name: "Article",
	components: {
		TheArticleTitle,
		CompleteArticleLoading,
		CompleteArticleLoaded,
		TenTags,
		ShareThisArticle,
	},
	data() {
		return {
			loaded: false,
			articleImage: "",
			publishDate: "",
			tags: [],
			viewCount: 0,
			articleImageCredits: "",
			articleContent: [],
		};
	},
	props: {
		articleTitle: { required: false, type: String },
		authorName: { required: false, type: String },
		authorUsername: { required: false, type: String },
		slug: { required: true, type: String },
	},
	created() {
		this.fetchArticle();
	},
	computed: {},
	methods: {
		fetchArticle: function() {
			let baseUrl = "https://shaoor-api.azurewebsites.net/api/";
			let articleUrl = `${baseUrl}articles/${this.slug}`;
			axios.get(articleUrl).then(res => {
				if (res.data.articleNotFound) {
					this.$router.push("/");
				} else {
					this.articleImage = res.data.img.imgSrc;
					this.articleImageCredits = res.data.img.credit;
					this.publishDate = res.data.status.publishDate;
					this.tags = res.data.tags;
					this.viewCount = res.data.viewsCount;
					this.articleContent = res.data.content;
					typeof this.articleTitle === "undefined"
						? (this.articleTitle = res.data.title)
						: this.articleTitle;
					typeof this.authorName === "undefined"
						? (this.authorName = res.data.author.authorName)
						: this.authorName;
					typeof this.authorUsername === "undefined"
						? (this.authorUsername = res.data.author.authorUserName)
						: this.authorUsername;
					this.loaded = true;
				}
			});
		},
	},
};
</script>

<style scoped>
[v-cloak] {
	display: none;
}
</style>
