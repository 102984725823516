<template>
	<div class="share-this-article">
		<h4 class="text-xl">Know someone who would be amazed by this article?</h4>
		<h5 class="text-lg">Share it via:</h5>
		<ul class="mt-4">
			<li class="social-icon">
				<a
					:href="
						'https://www.facebook.com/sharer/sharer.php?u=https://shaoor.tech/article/' +
							slug
					"
					target="_blank"
				>
					<img
						src="./../assets/icons/facebook.svg"
						alt="Facebook Logo"
						height="40"
						width="40"
					/>
				</a>
			</li>
			<li class="social-icon">
				<a
					:href="
						'http://www.twitter.com/share?url=https://shaoor.tech/article/' +
							slug
					"
					target="_blank"
				>
					<img
						src="./../assets/icons/twitter.svg"
						alt="Twitter Logo"
						height="40"
						width="40"
					/>
				</a>
			</li>
			<li class="social-icon">
				<a
					href="#
					"
				>
					<img
						src="./../assets/icons/linkedin.svg"
						alt="Linkedin Logo"
						height="40"
						width="40"
					/>
				</a>
			</li>
			<li class="social-icon">
				<div class="sharethis-inline-share-buttons"></div>
				<a href="#">
					<img
						src="./../assets/icons/whatsapp.svg"
						alt="Whatsapp Logo"
						height="40"
						width="40"
					/>
				</a>
			</li>
		</ul>
	</div>
</template>
<script
	type="text/javascript"
	src="https://platform-api.sharethis.com/js/sharethis.js#property=60685935406a11001102dc41&product=inline-share-buttons"
	async="async"
></script>
<script>
export default {
	name: "ShareThisArticle",
	props: { slug: String },
};
</script>

<style scoped>
.social-icon {
	@apply inline-block float-left mr-8;
}
</style>
