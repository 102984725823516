<template>
	<div class="animate-pulse">
		<div
			class="h-40 md:h-96 w-full mt-3 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="inline-block w-2/12 h-4 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded mt-1"
		></div>
		<div
			class="h-6 w-4/4 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-4/4 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-1/5 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-4/4 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-4/5 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-2/6 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-4/4 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-9/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-11/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-12/12 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-1/6 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-4/5 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
		<div
			class="mt-2 h-6 w-2/6 opacity-30 dark:bg-blueGray-50 bg-blueGray-800 rounded"
		></div>
	</div>
</template>

<script>
export default { name: "CompleteArticleLoading" };
</script>

<style scoped></style>
